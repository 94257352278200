// import logo from './logo.svg';
import './App.css';

function App() {
  const now = new Date()
  const currentDay = now.getDate()
  const daysInCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()
  // 第一张卡
  const totalData = 155 // GB
  const usedData = (currentDay / daysInCurrentMonth) * totalData
  const leftData = totalData - usedData
  // 第二张卡
  const totalData2 = 30 // GB
  const usedData2 = (currentDay / daysInCurrentMonth) * totalData2
  const leftData2 = totalData2 - usedData2
  return (
    <div className="App">
      <header className="App-header">
        {/*<img src={logo} className="App-logo" alt="logo"/>*/}
        <ul>
          <li>日期: {now.toLocaleString()}</li>
          <li>本月天数：{daysInCurrentMonth}</li>
          <br/>
          <li>卡一</li>
          <li>总流量：{totalData} GB</li>
          <li>可用流量：{usedData} GB</li>
          <li>剩余流量：{leftData} GB</li>
          <br/>
          <li>卡二</li>
          <li>总流量：{totalData2} GB</li>
          <li>可用流量：{usedData2} GB</li>
          <li>剩余流量：{leftData2} GB</li>
        </ul>
        {/*<a*/}
        {/*  className="App-link"*/}
        {/*  href="https://reactjs.org"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  Learn React*/}
        {/*</a>*/}
      </header>
    </div>
  );
}

export default App;
